import React, { useEffect } from 'react'
import './OtpVerification.css'
import { useUser } from '../../_contexts/UserContext/UserContext';
import { UserData } from '../../_models/User.model';
import { Card, Col, Row, Form, Typography, Input, Flex, Button } from 'antd';
import { UserService } from '../../_services/user.service';
import { globalRoute } from '../../_services/globalRoute.service';
import { useNavigate } from 'react-router-dom';
import { NotificationSvc } from '../../_services/notification.service';
import { TokenService } from '../../_services/token.service';
import CoverImage from '../../_components/CoverImage/CoverImage';
import CoverContent from '../../_components/CoverContent/CoverContent';
import Loader from '../../widgets/loader/loader';

const OtpVerification = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const { userData, setUserData } = useUser();
    const navigate = useNavigate();
    const [form] = Form.useForm();


    const onFinish = (values: UserData) => {
        const newValue: UserData = {
            email: userData?.email || '',
            country: userData?.country,
            sector: userData?.sector,
            otp: values.otp
        }
        UserService.generateUserToken(newValue).then((result: any) => {
            console.log(result)
            if (result.success) {
                TokenService.setToken(result.payload)
                navigate('/survey')
            } else {
                NotificationSvc.openErrorMessage('Invalid OTP')
            }
        })
    };

    const onResendOtp = (e: any) => {
        console.log('resend otp')
        e.preventDefault()
        console.log(e)
        const newValue: UserData = {
            email: userData?.email || '',
            country: userData?.country,
            sector: userData?.sector,
        }
        setLoading(true)
        UserService.resendOtp(newValue).then((response: any) => {
            setLoading(false)
            if (response.data.success) {
                NotificationSvc.openInfoMessage("Otp resend to your mail Id")
            } else {
                NotificationSvc.openInfoMessage("Oops! Something went wrong. Please try again.")
            }
        })
    }

    useEffect(() => {
        if (userData === null) {
            navigate('/')
        }
    }, [])






    return (
        <>
            <CoverImage coverImageUrl='./Get_Started_header.png'>
                <CoverContent title={<><div>GET STARTED</div><div>WITH SCAN-R</div></>} />
            </CoverImage>

            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 14, offset: 5 }} md={{ span: 20, offset: 2 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Card>
                            <Form layout="vertical" name="company-details" form={form} onFinish={onFinish} className="user-details-form-container">
                                <Typography.Title level={5} className="sub-heading marginTop-0">OTP Verification</Typography.Title>
                                <div className="block-desc">If you haven't received your otp please check your spam.</div>
                                <Form.Item label="OTP" name='otp' validateFirst rules={[{ required: true, max: 6, type: 'string', message: 'Please Enter OTP' }]}>
                                    <Input placeholder="Enter OTP" />
                                </Form.Item>
                                <Flex justify="flex-end" gap={4}>
                                    {/* <Button type="primary" className='teritory-btn' onClick={onResendOtp}>Resend OTP</Button> */}
                                    <Button type="primary" htmlType="submit" className="primary-btn">Submit</Button>
                                </Flex>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                <Loader loading={loading} />
            </div>
        </>
    )
}

export default OtpVerification
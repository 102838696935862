import { Questionnaire } from "../_models/Questionnaire.model";
import { UserData } from "../_models/User.model";
import { ApiSvc } from "./api.service";

let convertQuestionnaire = (originalData: any) => {
    let convertedData: { questionnaire: Questionnaire, response: any } = { questionnaire: {}, response: {} } as { questionnaire: Questionnaire, response: any };
    convertedData.questionnaire = {
        id: "questionnaire1",
        sections: originalData.map((sectionData: any, sectionIndex: number) => {
            let sectionQuestions = sectionData.question.map((question: any, index: number) => {
                convertedData.response[question.questionId] = question.response;
                return {
                    id: question.questionId,
                    label: question.question,
                    type: question.questionType,
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ],
                    rules: [
                        { required: question.required || false, message: "Please select an answer" }
                    ],
                    response: question.response
                };
            });

            return {
                id: sectionData.sectionId,
                label: sectionData.section,
                questions: sectionQuestions
            };
        })
    };
    return convertedData;
}

let convertAnswer = (questionnaireData: any, response: any) => {
    const desiredData = questionnaireData.sections.map((section: any) => ({
        sectionId: section.id,
        section: section.label,
        question: section.questions.map((question: any) => {
            return {
                questionId: question.id,
                question: question.label,
                questionType: question.type,
                required: question.rules[0].required, // Assuming there's only one rule and it's always there
                response: response.hasOwnProperty(question.id) ? response[question.id] : null // Set response to null initially}
            }
        })
    }));
    return desiredData;
}





export const QuestionnaireService = {
    QUESTIONNAIRE_BASE_URL: '/Questionnaire',
    USER_BASE_URL: '/User',



    getQuestionnaire: async (data: string) => {
        const response = await ApiSvc.get(`${QuestionnaireService.QUESTIONNAIRE_BASE_URL}/get?email=${data}`);
        if (response.data.success) {
            if (response.data.payload !== null) {
                response.data.payload = convertQuestionnaire(response.data.payload);
            }
            return response;
        }
    },

    uploadQuestionnaire: async (file: any) => {
        const files = file.file
        const formData = new FormData()
        formData.append('file', files)
        return ApiSvc.post(`${QuestionnaireService.QUESTIONNAIRE_BASE_URL}/upload`, formData)
    },

    downloadQuestionnaire: async () => {
        const response = await ApiSvc.get(`${QuestionnaireService.QUESTIONNAIRE_BASE_URL}/download`, { responseType: "blob" })
        return response
    },


    getQuestionnaireResponse: async (data: UserData) => {
        return ApiSvc.get(`${QuestionnaireService.QUESTIONNAIRE_BASE_URL}/questionnaire-response`);
    },

    saveQuestionnaireResponse: async (questionnaire: any, resp: any, user: string) => {
        const updateResponse = convertAnswer(questionnaire, resp)
        return ApiSvc.post(`${QuestionnaireService.USER_BASE_URL}/save?email=${user}`, updateResponse);

    },

    submitQuestionnaireResponse: async (questionnaire: Questionnaire, resp: any, user: string) => {
        const updateResponse = convertAnswer(questionnaire, resp)
        const encodedUrl = encodeURI(user);
        return ApiSvc.post(`${QuestionnaireService.USER_BASE_URL}/submit?email=${encodedUrl}`, updateResponse);
    },
    createUser: async (data: UserData) => {
        return ApiSvc.post(`${QuestionnaireService.USER_BASE_URL}/create`, data);
    },

};
import React, { Children, useEffect } from 'react';
import { Divider, Dropdown, Flex, Menu, MenuProps, Space } from "antd";
import './Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import SubMenu from 'antd/es/menu/SubMenu';

interface Item {
  key: string;
  label: string;
  danger?: boolean;
  path: string;
  children?: Item[];
}

const NavBar = () => {
  const navigate = useNavigate();
  const items: Item[] = [{ key: "1", label: "Get Started", danger: true, path: "/user-details" }, {
    key: "2", label: "About SCAN-R", path: "/about-us", children: [
      { key: "2.1", label: "How it Works", path: "/how-it-works" },
      { key: "2.2", label: "FAQ", path: "/frequently-asked-questions" }
    ]
  }, { key: "3", label: "Contact Us", path: "/contact-us" }]
  const [selectedItem, setSelectedItem] = React.useState<MenuItemType>()
  const location = useLocation();
  useEffect(() => {
    let pathToCheck = location.pathname;
    if(pathToCheck == "/"){
      setSelectedItem(undefined)
    } else {
      const item = items.find((i) => (i.path === pathToCheck) || (i.children && i.children.find((c) => c.path === pathToCheck)));
      if (item) {
        setSelectedItem(item);
      }
    }
  }, [location])
  const onMenuItemSelect = (item: any) => {
    const findPath = (items: any[], key: string): string | undefined => {
      for (const i of items) {
        if (i.key === key && i.path) {
          return i.path;
        }
        if (i.children) {
          const childPath = findPath(i.children, key);
          if (childPath) {
            return childPath;
          }
        }
      }
      return undefined;
    };
    const selectedPath = findPath(items, item.key);
    if (selectedPath) {
      navigate(selectedPath);
    }


  }
  const isDanger = (item: any) => {
    console.log(item)
    return item.danger === true;
  };

  return (
    <>
      <Flex><img className='cursor-pointer' src="./logo.jpg" height={"80"} onClick={() => { navigate("/") }} /></Flex>
      <Menu
        theme="light"
        mode="horizontal"
        selectedKeys={[selectedItem?.key?.toString() || ""]}
        className="menu-item"
        onClick={onMenuItemSelect}
      >
        {items.map(item => {
          if (item.children && item.children.length > 0) {
            const menuProps = {
              items: item.children,
              onClick: (e: any) => {
                onMenuItemSelect(item.children?.find((i) => i.key === e.key));
                e.domEvent.stopPropagation();
              },
            };
            return (
              <Menu.Item key={item.key}>
                <Space>
                  <Dropdown.Button className='drop-down' menu={menuProps} onClick={(e) => onMenuItemSelect(item)} icon={<img src='./downarrow.png' className='menu-arrow-botton' />}>
                    {item.label}
                  </Dropdown.Button>
                </Space>
              </Menu.Item>
            );
          } else {
            return <Menu.Item key={item.key} danger={isDanger(item)}>{item.label}</Menu.Item>;
          }
        })}
      </Menu>
    </>
  )
};

export default NavBar;
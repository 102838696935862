import React from 'react'
import './HomePage.css'
import { Button, Col, Row, StepProps, Steps, } from 'antd'
import CoverImage from '../../_components/CoverImage/CoverImage';
import CoverContent from '../../_components/CoverContent/CoverContent';

const HomePage = () => {
    const item: StepProps[] = [
        {
            icon: <img src='./Assessment.png' alt="Assessment" style={{ width: '90px' }} />,
            title: <>
                <div className='color-teritory small-font'>1</div>
                <div className='color-teritory small-font'>Assessment</div>
            </>,
            subTitle: <div className='small-font'>Complete a questionnaire about company processes and policies</div>,
        },
        {
            icon: <img src='./DataAnalysis.png' alt="Data Analysis" style={{ width: '90px' }} />,
            title: <>
                <div className='color-teritory small-font'>2</div>
                <div className='color-teritory small-font'>Data Analysis</div>
            </>,
            subTitle: <div className='small-font'>SCAN-R leverages its risk database and readiness indicators to analyse your responses</div>
        },
        {
            icon: <img src='./DownloadReport.png' alt="Download Report" style={{ width: '90px' }} />,
            title: <>
                <div className='color-teritory small-font'>3</div>
                <div className='color-teritory small-font'>Download Report</div>
            </>,
            subTitle: <div className='small-font'>SCAN-R Report containing results and insights is generated and sent to your Email ID</div>
        },
        {
            icon: <img src='./TakeAction.png' alt="Take Action" style={{ width: '90px' }} />,
            title: <>
                <div className='color-teritory small-font'>4</div>
                <div className='color-teritory small-font'>Take Action</div>
            </>,
            subTitle: <div className='small-font'>Empower your company to kickstart its risk management based on these actionable insights</div>
        },
    ];




    return (
        <>
            <CoverImage coverImageUrl='./cover-image.png'>
                <CoverContent>
                    <Button className='primary-btn'><a className='font-weight-bold' href="/user-details">Get Started</a></Button>
                </CoverContent>
            </CoverImage>
            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                        <div className='sub-heading margin-0'>Kickstart your supply chain due diligence journey</div>
                        <div className='description'>The Supply Chain Risk Assessment Navigator, or SCAN-R, is a trusted digital tool for companies navigating the first step towards human rights and environmental risk management. SCAN-R provides essential insights to kickstart your supply chain due diligence journey:</div>
                        <Row gutter={24}>
                            <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 24 }} className='marginTop-30'>
                                <div className='center'>
                                    <img src="./RiskAnalysis.png" alt="" className='center' style={{ width: '130px' }} />
                                </div>
                                <div className='center font-weight-bold color-primary header-font-family'>Risk Analysis</div>
                                <div className='center marginTop-10'>based on company context</div>
                            </Col>
                            <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 24 }} className='marginTop-30'>
                                <div className='center'>
                                    <div className='center'>
                                        <img src="./ReadinessScore.png" alt="" style={{ width: '130px' }} />
                                    </div>
                                    <div className='center font-weight-bold color-primary header-font-family'>Readiness Score</div>
                                    <div className='center marginTop-10'>based on assessment of risk management processes</div>
                                </div>
                            </Col>
                            <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 24 }} className='marginTop-30'>
                                <div className='center'>
                                    <img src="./Recommendations.png" alt="" className='center' style={{ width: '130px' }} />
                                </div>
                                <div className='center font-weight-bold color-primary header-font-family'>Recommendations</div>
                                <div className='center marginTop-10'>for improvement</div>
                            </Col>
                        </Row>
                        <div className='button-style marginTop-30'>
                            <Button className='primary-btn'><a href="/user-details">Get Started</a></Button>
                        </div>
                        <div className='sub-heading section-header'>How it works</div>
                        <div className='how-it-work-step marginTop-30'>
                            <Steps current={-1} labelPlacement="vertical" items={item} />
                        </div>
                        <div className='button-style marginTop-30'>
                            <Button className='background-teritory'><a href="/how-it-works">Know More</a></Button>
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default HomePage
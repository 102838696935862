import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react'

const Loader = (props: { loading: boolean }) => {

    return (
        <Spin spinning={props.loading} indicator={<LoadingOutlined style={{ fontSize: 40, color: 'var(--PRIMARY_COLOR)' }} spin />} fullscreen />
    )
}

export default Loader
import React from 'react';
import './AboutUs.css'
import { Button, Col, Row } from 'antd';
import CoverImage from '../../_components/CoverImage/CoverImage';
import CoverContent from '../../_components/CoverContent/CoverContent';

const AboutUs = () => {
  return (
    <>
      <CoverImage coverImageUrl='./about_SCAN-R_header.png'>
        <CoverContent title='ABOUT SCAN-R'></CoverContent>
      </CoverImage>
      <div className='page-content'>
        <Row>
          <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
            <h1 className='heading'>About SCAN-R</h1>
            <div className='description'>The Supply Chain Risk Assessment Navigator, or SCAN-R, was developed to serve as a guiding compass for companies to navigate the way towards effective supply chain due diligence.</div>
            <div className='description'>SCAN-R conducts a context-based risk analysis and a due diligence capability assessment, based on responses provided by the user, to generate insights and recommendations that are valuable for companies looking to improve sustainability and resilience in their supply chains.</div>
            <div className='description'>SCAN-R prioritises user privacy. SCAN-R assesses entered responses against standardised indicators, automatically generating results while ensuring user anonymity throughout the process.</div>
            <div className='button-style'>
              <Button className='background-primary marginTop-30'><a href='how-it-works'>How it Works</a></Button>
            </div>
            <div className='sub-heading section-header'>Our Mission</div>
            <div className='description'>Every company, regardless of size, should have access to reliable tools and resources to assess supply chain risks effectively. By providing a user-friendly platform and actionable insights, SCAN-R aims to empower companies to strengthen their risk management practices and processes.</div>
            <Row gutter={24}>
              <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className=' marginTop-30'>
                <div className='center'>
                  <img src="./mission_1.png" alt="" style={{ width: '100px' }} className='center' />
                  <div className='color-teritory center font-weight-bold header-font-family'>Strengthen risk management</div>
                </div>
              </Col>
              <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className=' marginTop-30'>
                <div className='center'>
                  <img src="./mission_2.png" alt="" style={{ width: '100px' }} className='center' />
                  <div className='color-teritory center font-weight-bold header-font-family'>Continuous Improvement</div>
                </div>
              </Col>
              <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className=' marginTop-30'>
                <div className='center'>
                  <img src="./mission_3.png" alt="" style={{ width: '100px' }} className='center' />
                  <div className='color-teritory center font-weight-bold header-font-family'>Sustainable Growth</div>
                </div>
              </Col>
              <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} className=' marginTop-30'>
                <div className='center'>
                  <img src="./mission_4.png" alt="" style={{ width: '100px' }} className='center' />
                  <div className='color-teritory center font-weight-bold header-font-family'>Meet consumer & buyer standards</div>
                </div>
              </Col>
            </Row>
            <div className='sub-heading section-header'>Background</div>
            <div className='description'>Developed by supply chain due diligence experts, SCAN-R brings together a team of in-house and external experts with extensive experience and knowledge in supply chain risk assessment. With a deep understanding of industry best practices and international guidelines, SCAN-R ensures credibility and reliability in providing insights to help businesses navigate their supply chain risks effectively.</div>
            <div className='description'>SCAN-R is funded by the Agency for Business and Economic Development (AWE) on behalf of the German Federal Ministry for Economic Cooperation and Development (BMZ), and implemented by the Indo-German Chamber of Commerce (AHK India) in cooperation with the Delegation of German Industry and Commerce for Eastern Africa (through its service entity AHK Services Eastern Africa Ltd.)</div>
            <div className='description extra-small-font'>AWE is the central point of contact for the German and European business community interested in engaging with development cooperation. It specifically promotes partnerships in countries of the Global South. With its international network of experts, AWE offers advisory services on funding, financing and project development and establishes contacts worldwide.</div>
            <div className='sub-heading section-header'>Looking for implementation support?</div>
            <div className='description'>The Indo-German Chamber of Commerce (IGCC) and the Delegation of German Industry and Commerce for Eastern Africa (through its service entity AHK Services Eastern Africa Ltd.) provide implementation support to Indian and East African companies looking to further their responsible business practices. To know more about how we support companies, get in touch with us. </div>
            <div className='button-style'>
              <Button className='background-primary marginTop-30'><a href='contact-us'>Click here</a></Button>
            </div>
          </Col>
        </Row>
      </div >
    </>
  )
};

export default AboutUs;
import { ApiSvc } from "./api.service"

let convertSector = (originalData: string[]) => {
    const updatedData = originalData.map((ele: string) => {
        return { value: ele, label: ele }
    })
    return updatedData
}

export const RiskService = {
    RISK_BASE_URL: '/CountryOrSectorRisks',
    COUNTRY_OR_SECTOR_RISK_BASE_URL: '/CountryOrSectorRisks',
    uploadRisk: async (file: any) => {
        const files = file.file
        const formData = new FormData()
        formData.append('file', files)
        return ApiSvc.post(`${RiskService.RISK_BASE_URL}/upload`, formData)
    },
    downloadRisk: async () => {
        const response = await ApiSvc.get(`${RiskService.COUNTRY_OR_SECTOR_RISK_BASE_URL}/download`, { responseType: "blob" })
        return response
    },
    getSector: async () => {
        const response = await ApiSvc.get(`${RiskService.COUNTRY_OR_SECTOR_RISK_BASE_URL}/getSector`);
        const updateResponse = convertSector(response.data.payload)
        return updateResponse;
    },
    getCountry: async () => {
        const response = await ApiSvc.get(`${RiskService.COUNTRY_OR_SECTOR_RISK_BASE_URL}/getCountry`);
        const updateResponse = convertSector(response.data.payload)
        return updateResponse;
    },
}
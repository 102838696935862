import { Col, Collapse, CollapseProps, Row } from 'antd'
import React from 'react'
import CoverImage from '../../_components/CoverImage/CoverImage';
import CoverContent from '../../_components/CoverContent/CoverContent';
import './FAQ.css'
const items: CollapseProps['items'] = [
    {
        key: '1',
        label: <>
            <div className='header-font-family font-weight-bold'>What is SCAN-R?</div>
        </>,
        children: <p>SCAN-R, short for Supply Chain Risk Assessment Navigator, is a digital tool designed to assist companies operating in India and the Eastern Africa region in comprehensively assessing human rights and environmental risks within their supply chains. By identifying risks relevant to companies and assessing their internal policies and processes, SCAN-R provides companies with valuable insights, readiness scores, and actionable recommendations to strengthen their supply chain due diligence processes.</p>,
    },
    {
        key: '2',
        label: <>
            <div className='header-font-family font-weight-bold'>Why was SCAN-R developed?</div>
        </>,
        children: <p>SCAN-R was primarily developed to provide small and medium-sized enterprises with a starting point to navigate supply chain due diligence. SCAN-R aims to be a guiding compass for businesses, aligning international guidelines and the evolving landscape of corporate human rights and environmental directives.</p>,
    },
    {
        key: '3',
        label: <>
            <div className='header-font-family font-weight-bold'>How does SCAN-R work?</div>
        </>,
        children: <p>SCAN-R operates through a systematic and user-friendly process where users are first required to select the country and sector relevant to their company.  Users are then presented with a comprehensive self-assessment questionnaire regarding their company’s supply chain due diligence processes. This input is utilised to generate a comprehensive SCAN-R report containing an analysis of risks identified in the company’s context, and the assessment results along with recommendations for improvement.</p>,
    },
    {
        key: '4',
        label: <>
            <div className='header-font-family font-weight-bold'>How does SCAN-R identify risks in my company’s context?</div>
        </>,
        children: <p>Based on the country and sector relevant to your company, SCAN-R leverages a risk database developed by experts to generate high and medium risks for your company. The SCAN-R risk database contains information gathered by experts through a multi-faceted approach to data collection.</p>,
    },
    {
        key: '5',
        label: <>
            <div className='header-font-family font-weight-bold'>How does SCAN-R ensure accuracy in the risks identified?</div>
        </>,
        children: <>
            <p>Risks in the SCAN-R risk database are corroborated using information from credible sources including government agencies, international development organisations, and NGOs. The risk database is periodically reviewed and updated to ensure up-to-date and accurate information.</p>
            <p>It is important to acknowledge that complete infallibility in the risks identified and the sources provided cannot be guaranteed.</p>
        </>,
    },
    {
        key: '6',
        label: <>
            <div className='header-font-family font-weight-bold'>Does SCAN-R provide ranking or prioritisation of risks?</div>
        </>,
        children: <p>No, risks identified by SCAN-R do not constitute a prioritised or ranked listing of risks.  SCAN-R is intended to serve as a starting point for companies to start their own risk identification and assessment processes.</p>,
    },
    {
        key: '7',
        label: <>
            <div className='header-font-family font-weight-bold'>How does SCAN-R measure a company’s internal policies and processes?</div>
        </>,
        children: <>
            <p>SCAN-R employs a customised framework based on pre-established assessment indicators (‘Effective Supply Chain Due Diligence Indicators’) to analyse companies’ supply chain due diligence policies and processes.</p>
            <p>Based on the responses in the assessment questionnaire, SCAN-R calculates a Component Score for each section and the total Aggregated Score on the basis of pre-determined weightages in line with these indicators.</p>
        </>,
    },
    {
        key: '8',
        label: <>
            <div className='header-font-family font-weight-bold'>How does SCAN-R generate recommendations to improve companies’ internal policies and processes?</div>
        </>,
        children: <p>Based on the responses in the assessment questionnaire, SCAN-R analyses companies’ supply chain due diligence policies and processes and generates recommendations for improvement in line with ‘Effective Supply Chain Due Diligence Indicators’.</p>,
    },
    {
        key: '9',
        label: <>
            <div className='header-font-family font-weight-bold'>What are ‘Effective Supply Chain Due Diligence Indicators’?</div>
        </>,
        children: <p>SCAN-R’s algorithm uses a framework of <b><i>“Effective Supply Chain Due Diligence Indicators”</i></b> to standardise the assessment process. These indicators integrate industry expertise with recognised international guidelines including the United Nations Guiding Principles on Business and Human Rights, the OECD Guidelines for Multinational Companies, and the German Federal Office for Economic Affairs and Export Control’s guidelines on various components of the German Supply Chain Due Diligence Act.  For further information, <a href="https://www.sustainmarkets.in/wp-content/uploads/Effective-SCDD-Indicators-for-SMEs.pdf" target='_blank' className='link'>please click here.</a></p>,
    },
    {
        key: '10',
        label: <>
            <div className='header-font-family font-weight-bold'>Why is my email address needed to use SCAN-R?</div>
        </>,
        children: <p>Once the assessment questionnaire has been submitted, the SCAN-R report is sent to the email ID provided by the user.</p>,
    },
    {
        key: '11',
        label: <>
            <div className='header-font-family font-weight-bold'>Are my responses confidential? Who can view my results?</div>
        </>,
        children: <>
            <p>Yes, all questionnaire responses are confidential. SCAN-R prioritises the privacy and security of information. Only authorised personnel involved in the development and maintenance of SCAN-R have access to this data, which is handled with strict adherence to data protection regulations. <a href="/terms-of-use" className='link'>To learn more about our privacy policy, please click here.</a></p>
            <p>Assessment results and SCAN-R report are exclusively accessible to the email ID provided by the user. SCAN-R is committed to safeguarding your data and ensuring a secure starting point for your risk assessment journey.</p>
        </>,
    },
    {
        key: '12',
        label: <>
            <div className='header-font-family font-weight-bold'>Why does SCAN-R only cover certain countries and sectors?</div>
        </>,
        children: <p>
            We launched SCAN-R to support small and medium businesses (SMEs) in India and East Africa, where we have a strong network of local experts. This initial focus allows us to provide the best possible support by leveraging regional knowledge. We are looking for partners to help us expand SCAN-R support to other regions and sectors. If you're interested in collaborating with us to expand our support, write to us at <a href='mailto:support@scan-r.info' className='link'>support@scan-r.info</a>
        </p>,
    }
]
const FAQ = () => {
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    return (
        <>
            <CoverImage coverImageUrl='./about_SCAN-R_header.png'>
                <CoverContent title='FAQs'></CoverContent>
            </CoverImage>
            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                        <h1 className='heading'>Frequently Asked Questions</h1>
                        <div className='description'>
                            <Collapse accordion items={items} defaultActiveKey={['1']} onChange={onChange} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default FAQ

import React, { useEffect } from 'react';
import { Typography } from "antd";
import CoverImage from "../../_components/CoverImage/CoverImage";
import './ThankYou.css';
import { TokenService } from '../../_services/token.service';
import CoverContent from '../../_components/CoverContent/CoverContent';

const ThankYou = () => {
  useEffect(() => {
    TokenService.clearToken();
  }, [])
  return (
    <>
      <CoverImage coverImageUrl='./cover-image.png'>
        <CoverContent></CoverContent>
      </CoverImage>
      <div className="page-content">
        <div className="text-center">
          <img src="./tick_icon.png" width={100} />
        </div>
        <Typography.Title className="text-center color-primary header-font-family">Thank You!</Typography.Title>
        <Typography.Title level={5} className="text-center submit-info">Your SCAN-R Report has been sent to your email.</Typography.Title>
      </div>
    </>
  )
};

export default ThankYou;
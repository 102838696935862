import React, { ReactNode } from 'react'
import './CoverTitle.css'
interface CoverTitleProps {
    title?: string | ReactNode;
}
const CoverTitle = (props: CoverTitleProps) => {
    return (
        <div className='cover-title'>
            {
                props.title ? props.title : <div>
                    <div>SUPPLY CHAIN</div>
                    <div>RISK ASSESSMENT</div>
                    <div>NAVIGATOR</div>
                </div>
            }
        </div>
    )
}

export default CoverTitle

import React from 'react';
import { useEffect, useState } from "react";
import CoverImage from "../../_components/CoverImage/CoverImage";
import { Questionnaire } from "../../_models/Questionnaire.model";
import { StepProps } from "antd";
import { QuestionType } from "../../_types/Question.type";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../_contexts/UserContext/UserContext";
import './Survey.css';
import SurveyForm from '../../_components/SurveyForm/SurveyForm';
import { QuestionnaireService } from '../../_services/questionnaire.service';
import { NotificationSvc } from '../../_services/notification.service';
import CoverContent from '../../_components/CoverContent/CoverContent';


const Survey = () => {
    const navigate = useNavigate()
    const { userData, setUserData } = useUser();
    const [questionnaire, setQuestionnaire] = useState<Questionnaire>({} as Questionnaire);
    const [response, setResponse] = useState({});

    const initData = () => {
        if (userData && userData?.email) {
            QuestionnaireService.getQuestionnaire(userData?.email).then((result: any) => {
                if (result.data.success) {
                    if (result.data.payload !== null) {
                        setQuestionnaire(result.data.payload.questionnaire);
                        setResponse(result.data.payload.response);
                    } else {
                        console.log('Already Submited')
                        navigate('/already-submited')
                    }
                } else {
                    NotificationSvc.openErrorMessage(result.data.message)
                }
            })
        }
    }

    console.log(questionnaire, "Questionaannnnn")
    console.log(response, "response in Survey")

    useEffect(() => {
        //document.title = "SELF-ASSESSMENT QUESTIONNAIRE";
        initData();
    }, []);

    return (
        <>
            <CoverImage coverImageUrl='./Get_Started_header.png'>
                <CoverContent title='ASSESSMENT QUESTIONNAIRE' />
            </CoverImage>
            <div className="page-content">
                <SurveyForm questionnaire={questionnaire} response={response} setResponse={setResponse} />
            </div >
        </>
    )
};

export default Survey;
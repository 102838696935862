import React, { useState } from 'react';
import { Button, Card, Col, Flex, Form, Input, Row, Select, Spin, Typography } from "antd";
import CoverImage from "../../_components/CoverImage/CoverImage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "../../_contexts/UserContext/UserContext";
import { UserData } from "../../_models/User.model";
import './UserDetailsForm.css';
import { RiskService } from '../../_services/risk.service';
import { SectorCountry } from '../../_models/user_model/Sector.Country.model';
import { UserService } from '../../_services/user.service';
import { LoadingOutlined } from '@ant-design/icons';
import Loader from '../../widgets/loader/loader';
import { error } from 'console';
import CoverContent from '../../_components/CoverContent/CoverContent';


const UserDetailsForm = () => {
    const { userData, setUserData } = useUser();
    const [sectorOptions, setSectorOptions] = useState<SectorCountry[]>([])
    const [countryOptions, setCountryOptions] = useState<SectorCountry[]>([])
    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const onFinish = (values: UserData) => {
        const userCredentials: UserData = {
            email: values.email,
            country: values.country,
            sector: values.sector
        }
        console.log(userCredentials)
        setUserData(userCredentials);
        setLoading(true)
        UserService.createUser(userCredentials).then((response) => {
            if (response.data.success) {
                setLoading(false)
                navigate("/otp-verification")
            }
        }).catch(error => {
            if (error)
                setLoading(false)
        })
    };
    const initData = () => {
        RiskService.getSector().then((response: SectorCountry[]) => {
            setSectorOptions(response)
        })
        RiskService.getCountry().then((response: SectorCountry[]) => {
            setCountryOptions(response)
        })
    }
    useEffect(() => {
        //document.title = "SUPPLY CHAIN RISK ASSESSMENT NAVIGATOR";
        initData()
    }, [])
    return (
        <>
            {/* <CoverImage title="SUPPLY CHAIN RISK ASSESSMENT NAVIGATOR" /> */}

            <CoverImage coverImageUrl='./Get_Started_header.png' children={<CoverContent title={<><div>GET STARTED</div><div>WITH SCAN-R</div></>}></CoverContent>} />
            <div className="page-content">
                <Row>
                    <Col lg={{ span: 14, offset: 5 }} md={{ span: 20, offset: 2 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Card>
                            <Form layout="vertical" name="company-details" form={form} onFinish={onFinish} className="user-details-form-container">
                                <Typography.Title level={5} className="sub-heading marginTop-0">Company Details</Typography.Title>
                                <div className="block-desc">Please select your country and sector. SCAN-R will use this information to generate insights about risks in your business context.</div>
                                <Form.Item label="Country" name='country' rules={[{ required: process.env.REACT_APP_PRODUCTION_MODE == 'true', message: "Please enter country" }]}>
                                    <Select
                                        placeholder="Select country"
                                        options={countryOptions}
                                    />
                                </Form.Item>
                                <Form.Item label="Sector" name='sector' rules={[{ required: process.env.REACT_APP_PRODUCTION_MODE == 'true', message: "Please enter sector" }]}>
                                    <Select
                                        placeholder="Select sector"
                                        options={sectorOptions}
                                    />
                                </Form.Item>
                                <hr className="block-separator" />
                                <Typography.Title level={5} className="sub-heading marginTop-0">Email ID</Typography.Title>
                                <div className="block-desc">Please enter your Email ID carefully. You will receive a link to download your report via Email.</div>
                                <Form.Item label="" name='email' rules={[{ required: true, type: 'email', message: "Please enter Email ID" }]}>
                                    <Input placeholder="Enter Email ID" />
                                </Form.Item>
                                <Flex justify="flex-end">
                                    <Button type="primary" htmlType="submit" className="primary-btn">Get Started</Button>
                                </Flex>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Loader loading={loading} />
        </>
    )
};

export default UserDetailsForm;
import { Questionnaire } from "../_models/Questionnaire.model";
import { AdminData } from "../_models/User.model";
import { AllUser } from "../_models/admin_model/Alluser.model";
import { ApiSvc } from "./api.service";

let convertQuestionnaire = (originalData: any) => {
    let convertedData: { questionnaire: Questionnaire, response: any } = { questionnaire: {}, response: {} } as { questionnaire: Questionnaire, response: any };
    convertedData.questionnaire = {
        id: "questionnaire1",
        sections: originalData.map((sectionData: any, sectionIndex: number) => {
            let sectionQuestions = sectionData.question.map((question: any, index: number) => {
                convertedData.response[question.questionId] = question.response;
                return {
                    id: question.questionId,
                    label: question.question,
                    type: question.questionType,
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ],
                    rules: [
                        { required: question.required || false, message: "This field is required" }
                    ],
                    response: question.response
                };
            });

            return {
                id: sectionData.sectionId,
                label: sectionData.section,
                questions: sectionQuestions
            };
        })
    };
    return convertedData;
}

let convertAllUserStatus = (data: any) => {
    data.forEach((element: any) => {
        if (element.status === true) {
            element.status = 'Completed'
        } else {
            element.status = 'In Progress'
        }
    });
    return data
}

export const AdminServices = {
    ADMIN_BASE_URL: '/Admin',

    generateAdminToken: async (data: AdminData) => {
        const response = await ApiSvc.post(`${AdminServices.ADMIN_BASE_URL}/generateToken`, data);
        return response?.data
    },
    getAllUser: async () => {
        const response = await ApiSvc.get(`${AdminServices.ADMIN_BASE_URL}/getAllUser`);
        if (response && response.data.success) {
            const convertAllUser = convertAllUserStatus(response.data.payload)
            return convertAllUser
        }
    },
    viewUserDetails: async (email: string) => {

        const response = await ApiSvc.get(`${AdminServices.ADMIN_BASE_URL}/viewUser?email=${email}`);
        if (response.data.success) {
            const convertedData = convertQuestionnaire(response.data.payload);
            return convertedData;
        }
    },

    getReport: async (data: any) => {
        return ApiSvc.post(`${AdminServices.ADMIN_BASE_URL}/download`, data, { responseType: 'blob' })
    },


    changePassword: async (credentialData: any) => {
        return ApiSvc.post(`${AdminServices.ADMIN_BASE_URL}/changePassword`, credentialData)
    }




}
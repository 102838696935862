import React from 'react';
import { Col, Flex, Row } from "antd";
import "./Footer.css";

const Footer = () => {
    const items = [{ label: 'About SCAN-R', path: 'about-us' }, { label: 'How it Works', path: 'how-it-works' }, { label: 'FAQs', path: 'frequently-asked-questions' }, { label: 'Contact Us', path: 'contact-us' }, { label: 'Terms of Use', path: 'terms-of-use' }]
    return (
        <div className='footer-container'>
            <div className='header header-font-family'>Quick Links</div>
            <div className="menu-item-container">
                {
                    items.map((item: any, index: number) => {
                        return <>
                            <span><a href={item.path} className='footer-link-color'>{item.label}</a></span>
                            {items.length !== index + 1 ? <span><span className='dot'></span></span> : ''}
                        </>
                    }

                    )
                }
            </div>
        </div>
    )
};

export default Footer;
import { globalRoute } from "../_services/globalRoute.service"
import { TokenService } from "../_services/token.service"
import { Role } from "../_types/Role.type"

interface AuthGuardProps {
    element: any,
    role: Role
}
export const AuthGuard = ({ element, role }: AuthGuardProps) => {
    if (!TokenService.isValidRole(role)) {
        goToDefaultRoute(role)
        return false
    }
    return element
}


export const goToDefaultRoute = (role: Role) => {
    if (role == Role.ADMIN) {
        TokenService.clearToken();
        window.location.href = '/login'
    } else {
        TokenService.clearToken();
        window.location.href = '/user-details'
    }
}
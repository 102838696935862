import React, { createContext, useContext, useState } from "react";
import { AdminData, UserData } from "../../_models/User.model";

// Define user context types
interface UserContextType {
  userData: UserData | AdminData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | AdminData | null>>;
}

// Create user context
const UserContext = createContext<UserContextType>({
  userData: null,
  setUserData: () => {},
});

// User Provider
export const UserProvider: React.FC<{children:any}> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | AdminData | null>(null);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access user context
export const useUser = () => useContext(UserContext);
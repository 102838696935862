import { Button, Col, Row, StepProps, Steps } from 'antd';
import React from 'react'
import './HowItWorks.css'
import CoverContent from '../../_components/CoverContent/CoverContent';
import CoverImage from '../../_components/CoverImage/CoverImage';
import { finished } from 'stream';

const HowItWorks = () => {
    const ulStyle: React.CSSProperties = {
        listStyleType: 'lower-roman',
    };

    const items: StepProps[] =
        [
            {
                title: <div className='color-primary font-wight-bold small-font'>Step 1: User Input</div>,
                status: 'process',
                description: <>
                    <div className='step-inner-container'>
                        <div className='extra-small-font'>
                            <span className='font-weight-bold extra-small-font header-font-family'>Company Context</span><br />
                            Begin by providing essential information about your business operations including sector and country. This sets the foundation for SCAN-R to tailor its risk analysis to your specific business needs.

                        </div>
                        <div className='extra-small-font marginTop-20'>
                            <span className='font-weight-bold extra-small-font header-font-family'>Email ID</span><br />
                            Provide an email ID for SCAN-R to send you your results in the form of a report.

                        </div>
                        <div className='extra-small-font marginTop-20'>
                            <span className='font-weight-bold extra-small-font header-font-family'>Assessment Questionnaire</span><br />
                            Next, dive into our self-assessment questionnaire designed to gather insights into your supply chain due diligence (SCDD) processes. This questionnaire covers various aspects of your operations, allowing SCAN-R to identify potential risks and areas for improvement.
                        </div>
                    </div>
                </>,
            },
            {
                title: <><div className='color-primary font-weight-bold small-font'>Step 2: Data Analysis</div></>,
                status: 'process',
                description: <>
                    <div className='step-inner-container'>
                        <div className='extra-small-font '>SCAN-R utilises advanced algorithms to analyse your responses, leveraging a comprehensive risk database and effective SCDD indicators to provide accurate and actionable insights.</div>
                    </div>
                </>,
            },
            {
                title: <><div className='color-primary font-weight-bold small-font'>Step 3: Results</div></>,
                status: 'process',
                description: <>
                    <div className='extra-small-font step-inner-container'>
                        <span className='font-weight-bold extra-small-font header-font-family'>SCAN-R Report</span><br />
                        Your results are generated in the form of a report and sent to the Email ID provided, including:
                        <ul>
                            <li>
                                <span className='font-weight-bold header-font-family' > Risk Analysis</span> comprised of a first-step identification of potential human rights and environmental risks based on country and sector,
                            </li>
                            <li>
                                <span className='font-weight-bold header-font-family'>Readiness Score</span> based on current company processes against effective indicators, and
                            </li>
                            <li>
                                <span className='font-weight-bold header-font-family'>Recommendations</span> for improvement based on responses based on gaps identified in company processes.
                            </li>
                        </ul>
                    </div>
                </>
            },
            {
                title: <><div className='color-primary font-weight-bold small-font'>Step 4: Take Action</div></>,
                status: 'process',
                description: <>
                    <div className='step-inner-container'>
                        <div className='extra-small-font'>Use the insights gained from SCAN-R as a baseline to conduct your own risk assessment and improve your SCDD processes in line with recognised guidelines.</div>
                    </div>
                </>
            }
        ]

    return (
        <>
            <CoverImage coverImageUrl='./about_SCAN-R_header.png'>
                <CoverContent title='HOW SCAN-R WORKS'></CoverContent>
            </CoverImage>
            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                        <h1 className='heading'>How SCAN-R Works</h1>
                        <div className='description'>From risk identification to recommendations for improvement, SCAN-R simplifies the first step towards supply chain due diligence. SCAN-R uses advanced algorithms and expert analysis, enabling companies to navigate their risk management processes and take action.</div>
                        <div className='sub-heading section-header'>Process</div>
                        <div className='process-table-container marginLeft-30'>
                            <Steps direction='vertical' progressDot items={items} />
                        </div >
                        <div className='sub-heading section-header'>Indicators of Effectiveness</div>
                        <Row gutter={16} className='background-white'>
                            <Col lg={{ span: 4 }} md={{ span: 5 }} sm={{ span: 6 }} xs={{ span: 24 }} className='marginTop-10' >
                                <div>
                                    <a href='https://www.sustainmarkets.in/wp-content/uploads/Effective-SCDD-Indicators-for-SMEs.pdf' target='_blank'><img width={'100%'} src='./book.png' style={{ maxWidth: '100%' }} /></a>
                                </div>
                            </Col>
                            <Col lg={{ span: 20 }} md={{ span: 19 }} sm={{ span: 18 }} xs={{ span: 24 }} className='marginTop-10' style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <div>Effectiveness Indicators, utilized by SCAN-R, offer SMEs a starting point for initiating due diligence processes, aligning with German regulatory frameworks and international guidelines such as the UN and OECD. Emphasizing practicality, proportionality, and continuous improvement, these indicators guide SMEs towards meaningful actions tailored to their size and resources.</div>
                                    <div className='button-style marginTop-30 paddingBottom'>
                                        <Button className='primary-btn color-white font-weight-bold'><a href='https://www.sustainmarkets.in/wp-content/uploads/Effective-SCDD-Indicators-for-SMEs.pdf' target='_blank'>Effectiveness Indicators</a></Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='sub-heading section-header'>Want to know more?</div>
                        <div className='description'>Check out our Frequently Asked Questions.</div>
                        <div className='button-style'>
                            <Button className='primary-btn color-white font-weight-bold marginTop-30'><a href='frequently-asked-questions'>FAQs</a></Button>
                        </div >
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default HowItWorks

import React from 'react'
import './TermsOfUse.css'
import { Col, Row } from 'antd'
import CoverImage from '../../_components/CoverImage/CoverImage'
import CoverContent from '../../_components/CoverContent/CoverContent'

const TermsOfUse = () => {
    return (
        <>
            <CoverImage coverImageUrl='./cover-image.png'>
                <CoverContent title='TERMS OF USE' />
            </CoverImage>
            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                        <h1 className='heading'>Terms of Use</h1>
                        <div className='description'>These Terms of Use govern your access and use of the Supply Chain Risk Assessment Navigator ("SCAN-R"). By accessing or using SCAN-R, you agree to these Terms.</div>
                        
                        <div className='sub-heading section-header'>Disclaimer</div>
                        <div className='description'>SCAN-R and any information obtained through SCAN-R, including but not limited to identified sector-specific and country-specific risks, assessment scores, and management process recommendations, is intended for informational and educational purposes only. It is not intended to constitute professional or legal advice. Reliance on any such information shall be at the User’s own risk.</div>
                        <div className='description'>Under no circumstances shall the Indo-German Chamber of Commerce (IGCC) and its partners be liable for any loss, damage, liability, or expense incurred or suffered that is claimed to have resulted from the use of the SCAN-R, including but not limited to, direct, indirect, incidental, consequential, or punitive damages.</div>
                        <div className='description'>SCAN-R is provided "as is" without any warranties, express or implied. We make no representations or warranties regarding the accuracy, completeness, or reliability of the information provided. We disclaim any liability for any errors or omissions in the content of SCAN-R and for any damages arising from them.</div>
                        <div className='description'>SCAN-R and any information obtained through SCAN-R, including but not limited to identified sector-specific and country-specific risks, assessment scores, and management process recommendations, do not reflect the expression of any opinion whatsoever by any government entity.</div>
                        <div className='description'>SCAN-R may contain references and links to third-party sources and websites. SCAN-R has made reasonable efforts to ensure the veracity of the information contained within the external links. SCAN-R is not responsible for the content of any linked site or any link contained in a linked site. SCAN-R provides these links for informational and educational purposes only. The inclusion of a link or reference does not imply any endorsement of the linked site.</div>
                        
                        <div className='sub-heading section-header'>Data Privacy and Consent</div>
                        <div className='description'>SCAN-R may collect certain information related to the usage of SCAN-R including but not limited to details entered in the assessment questionnaire. This information will be used to analyse trends and usage of SCAN-R and to improve the user experience of SCAN-R.</div>
                        <div className='description'>IGCC and its partners are committed to protecting the information maintained on the SCAN-R system from loss, misuse, unauthorised access or disclosure, alteration, or destruction. However, IGCC and its partners assume no warranty or liability for the security of information.</div>
                        <div className='description'>Data collected by SCAN-R will only be retained for the time required to fulfil the purpose of its collection. By using SCAN-R, you consent to the collection, use, and disclosure of your data in accordance with our <a href='https://indien.ahk.de/privacy-policy' target='_blank' className='link'>Privacy Policy</a>.</div>

                        <div className='sub-heading section-header'>Ownership, Usage and Access</div>
                        <div className='description'>IGCC and its partners are the owners of SCAN-R, its copyright and all rights pertaining to its use.</div>
                        <div className='description'>
                            The User agrees not to use SCAN-R for any illegal or unauthorised purpose, including but not limited to:
                            <ul className='margin-0'>
                                <li>Interfering with or attempting to interfere with the servers or networks connected to SCAN-R,</li>
                                <li>Attempting to gain unauthorised access to SCAN-R or any other accounts, computer systems, or networks connected to SCAN-R,</li>
                                <li>Sending to SCAN-R anything that contains a virus, or any harmful files, or</li>
                                <li>Conducting any extensive tests that may harm or impair the proper working of SCAN-R.</li>
                            </ul>
                        </div>
                        <div className='description'>We do not guarantee that SCAN-R will be error-free or uninterrupted. There may be times when SCAN-R is unavailable due to maintenance or technical circumstances.</div>
                        <div className='description'>We may terminate your access to SCAN-R at any time, for any reason, without notice.</div>
                        <div className='description'>You may terminate your use of SCAN-R at any time.</div>

                        <div className='sub-heading section-header'>Contact Us</div>
                        <div className='description'>If you have any questions about these Terms, please contact us at <a href='mailto:support@scan-r.info' className='link'>support@scan-r.info</a></div>

                        <div className='sub-heading section-header'>Updates to the Terms</div>
                        <div className='description'>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this webpage. Your continued use of SCAN-R after the posting of revised Terms will constitute your acceptance of the revised Terms.</div>
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default TermsOfUse

import React from 'react';

const PageNotFound = () => {
    return (
      <>
        404 - Page Not Found
      </>
    )
  };
  
  export default PageNotFound;
import React from 'react';
import './Copyright.css';
const Copyright = () => {
  const images = [{ path: "./ind-ger.JPG", width: 180 }, { path: "./pit.png", width: 180 }, { path: "./AHK_SustainMarkets.svg", width: 240 }, { path: "./AHK-Logo.png", width: 105 }]
  return (
    <div>
      {/* <div className="footer-img-container">
        {
          images.map((image, index) => {
            return (
              <div key={index}>
                <img key={index} src={image.path} width={image.width} />
              </div>
            )
          })
        }
      </div> */}
      <img src='./footer.jpg' className='width-100'/>
      {/* <div className='copy-right-text'>
        2024 Copyrights © Indo-German Chamber of Commerce. All Rights Reserved.
      </div> */}
    </div>
  )
};

export default Copyright;
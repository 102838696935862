export enum QuestionType {
    SINGLELINE_TEXT = 'Singleline_Text',
    MULTILINE_TEXT = 'Multiline_Text',
    NUMBER = 'Number',
    SINGLE_SELECTION = 'Single_Selection',
    MULTI_SELECTION = 'Multi_Selection',
    CHECKBOX = 'Checkbox',
    RADIO = 'Radio',
    SLIDER = 'Slider', //single value
    RANGE = 'Range', //range of values
    DATE = 'Date',
    DATE_RANGE = 'Date_Range'
}
import React, { ReactNode } from 'react'
import CoverTitle from '../CoverTitle/CoverTitle'

const CoverContent = (props: { title?: string | ReactNode, children?: React.ReactNode }) => {
    return (
        <>
            <CoverTitle title={props.title} />
            {props.children && <div className='marginTop-20'>{props.children}</div>}
        </>
    )
}

export default CoverContent
import axios, { AxiosError } from "axios";
import { TokenService } from "./token.service";
import { globalRoute } from "./globalRoute.service";
import { NotificationSvc } from "./notification.service";
import { goToDefaultRoute } from "../_guards/Auth.guard";
import { Role } from "../_types/Role.type";

export const ApiSvc = {

    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    get: async (url: string, opt?: any) => {
        const response = await axios.get(`${ApiSvc.BASE_URL}${url}`, opt)
        return response
    },

    post: async (url: string, data?: any, headerData?: any) => {
        const response = await axios.post(`${ApiSvc.BASE_URL}${url}`, data, headerData);
        return response;
    },
}

const noTokenApi = [`${ApiSvc.BASE_URL}/Admin/generateToken`, `${ApiSvc.BASE_URL}/CountryOrSectorRisks/getSector`, `${ApiSvc.BASE_URL}/CountryOrSectorRisks/getCountry`, `${ApiSvc.BASE_URL}/User/create`, `${ApiSvc.BASE_URL}/User/generateToken`, `${ApiSvc.BASE_URL}/User/resendOtp`]
const adminApiEndpoint = [`${ApiSvc.BASE_URL}/Admin/changePassword`, `${ApiSvc.BASE_URL}/Admin/getAllUser`, `${ApiSvc.BASE_URL}/Admin/download`, `${ApiSvc.BASE_URL}/Questionnaire/download`, `${ApiSvc.BASE_URL}/Questionnaire/upload`, `${ApiSvc.BASE_URL}/CountryOrSectorRisks/upload`, `${ApiSvc.BASE_URL}/CountryOrSectorRisks/download`]
axios.interceptors.request.use(
    async (config) => {
        const apiEndpoint = config.url?.split('?')[0];
        console.log(apiEndpoint)
        if (apiEndpoint && noTokenApi.includes(apiEndpoint)) {
            return config
        } else {
            return bindToken(config)

        }

    },
    async (error) => {
        Promise.reject(error);
    }
)

const getCancelledApiConfig = () => {
    const controller = new AbortController();
    const cfg = {
        signal: controller.signal,
    };
    controller.abort('We gotta cancel this');
    return cfg;
}

const bindToken = (config: any) => {
    const token = TokenService.getToken();
    if (!TokenService.extractToken() || TokenService.isExpired()) {
        checkRoleApi(config)
        // goToDefaultRoute(Role.USER);

        //return null;
        return getCancelledApiConfig;
    }
    config.headers.Authorization = `Bearer ${token} `;
    return config;
}


axios.interceptors.response.use(async (config) => {
    return config

}, error => {
    console.log(error)

    const status = error.response ? error.response.status : null
    if (status === 401) {
        checkRoleApi(error.config)

    } else if (status === 500) {
        NotificationSvc.openErrorMessage('An exception occured')
    } else if (status === 400) {
        NotificationSvc.openErrorMessage(error.message)
    }
    else {
        NotificationSvc.openErrorMessage(error.message)
    }

})

const checkApi = (config: any) => {
    const url: string | undefined = config.url
    if (url && adminApiEndpoint.includes(url)) {
        goToDefaultRoute(Role.ADMIN)
        localStorage.clear()
    } else {
        goToDefaultRoute(Role.USER)
        localStorage.clear()
    }
}

const checkRoleApi = (config: AxiosError) => {
    const role = TokenService.getRole()
    if (role !== null) {
        goToDefaultRoute(role)
    } else {
        checkApi(config)
    }
}
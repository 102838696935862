import React from 'react';
import './CoverImage.css';

interface CoverImageProps {
  children: React.ReactNode;
  coverImageUrl?: string
}

const CoverImage = (props: CoverImageProps) => {
  return (
    <div className='cover-img' style={{ backgroundImage: `url(${props.coverImageUrl})` }}>
      <div className='cover-content-container'>{props.children}</div>
    </div>
  )
};

export default CoverImage;
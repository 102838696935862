import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './_pages/Layout/Layout';
import UserDetails from './_pages/UserDetails/UserDetailsForm';
import Survey from './_pages/Survey/Survey';
import ThankYou from './_pages/ThankYou/ThankYou';
import PageNotFound from './_pages/PageNotFound/PageNotFound';
import { ConfigProvider } from 'antd';
import { UserProvider } from './_contexts/UserContext/UserContext';
import { APP_CONSTANT } from './App.constant';
import AboutUs from './_pages/AboutUs/AboutUs';
import ContactUs from './_pages/ContactUs/ContactUs';
import Login from './_pages/Login/Login';
import ScrollToTop from './_components/ScrollToTop/ScrollToTop';
import OtpVerification from './_pages/OtpVerification/OtpVerification';
import AlreadySubmited from './_pages/AlreadySubmited/AlreadySubmited';
import { AuthGuard } from './_guards/Auth.guard';
import { Role } from './_types/Role.type';
import Loader from './widgets/loader/loader';
import TermsOfUse from './_pages/TermsOfUse/TermsOfUse';
import HomePage from './_pages/HomePage/HomePage';
import HowItWorks from './_pages/HowItWorks/HowItWorks';
import FAQ from './_pages/FAQ/FAQ';
const AdminRouter = React.lazy(() => import('./_modules/Admin/Admin.router'));

function App() {

  const theme = {
    token: {
      // Seed Token
      colorPrimary: APP_CONSTANT.STYLE.THEME.SECONDARY_COLOR,
      borderRadius: 1,
      fontFamily: "source-serif-pro, sans-serif",

      // Alias Token
      //colorBgContainer:  APP_CONSTANT.STYLE.THEME.WHITE_COLOR_LIGHT_TONE,
    }
  }

  const withAuth = (Component: any) => {

    return (props: any) => {
      return <AuthGuard role={Role.ADMIN} element={<Component {...props} />} />;
    };
  };

  return (
    <UserProvider>
      <ConfigProvider theme={theme} >
        <Suspense fallback={<Loader loading={true} />}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="user-details" element={<UserDetails />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
                <Route path="login" element={<Login />} />
                <Route path="how-it-works" element={<HowItWorks />} />
                <Route path="frequently-asked-questions" element={<FAQ />} />
                <Route index element={<HomePage />} />
                <Route path="otp-verification" element={<OtpVerification />} />
                <Route path="survey" element={<AuthGuard role={Role.USER} element={<Survey />} />} />
                <Route path="thank-you" element={<AuthGuard role={Role.USER} element={<ThankYou />} />} />
                <Route path="already-submited" element={<AuthGuard role={Role.USER} element={<AlreadySubmited />} />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="admin/*" Component={withAuth(AdminRouter)} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ConfigProvider>
    </UserProvider>
  );
}

export default App;

import React from 'react'
import CoverImage from '../../_components/CoverImage/CoverImage'
import { Typography } from 'antd'
import CoverContent from '../../_components/CoverContent/CoverContent'

const AlreadySubmited = () => {
    return (
        <>
            <CoverImage coverImageUrl='./cover-image.png'>
                <CoverContent title='' />
            </CoverImage>
            <div className="page-content">
                <div className="text-center">
                    <img src="./tick_icon.png" width={100} />
                </div>
                <Typography.Title className="text-center color-primary header-font-family">Already submited!</Typography.Title>
                <Typography.Title level={5} className="text-center submit-info">Your Submited SCAN-R Report already sent to your email.</Typography.Title>
            </div>
        </>
    )
}

export default AlreadySubmited
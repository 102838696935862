import { notification } from "antd"
import { IconType } from "antd/es/notification/interface"

export const NotificationSvc = {
    open:(message: string, type: IconType)=>{
        notification.open({
            message: message,
            type: type
        })
    },

    openInfoMessage: (message: string)=>{
        NotificationSvc.open(message, "info");
    },

    openSuccessMessage: (message: string)=>{
        NotificationSvc.open(message, "success");
    },

    openWarningMessage: (message: string)=>{
        NotificationSvc.open(message, "warning");
    },

    openErrorMessage: (message: string)=>{
        NotificationSvc.open(message, "error");
    }
}
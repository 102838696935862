import React from 'react';
import { Checkbox, DatePicker, Form, Input, Radio, Select, Slider } from "antd";
import { Question } from "../../_models/Question.model";
import { QuestionType } from "../../_types/Question.type";

const QuestionAnswer = (props: { question: Question, resp: any, isDisabled: boolean, onRespChange: (resp: any) => void , questionIndex: number}) => {

    const onChange = (e: any) => {
        props.onRespChange({ [props.question.id]: e.target.value })
    };

    const renderInput = () => {
        switch (props.question.type) {
            case QuestionType.SINGLELINE_TEXT:
                return <Input placeholder={props.question.placeholder} disabled={props.isDisabled} onChange={onChange} value={props.resp[props.question.id]} />;
            case QuestionType.MULTILINE_TEXT:
                return <Input.TextArea placeholder={props.question.placeholder} disabled={props.isDisabled} onChange={onChange} value={props.resp[props.question.id]} />;
            case QuestionType.SINGLE_SELECTION:
                return <Select onChange={onChange} value={props.resp[props.question.id]}
                    placeholder={props.question.placeholder}
                    disabled={props.isDisabled}
                    options={props.question.options}
                />
            case QuestionType.MULTI_SELECTION:
                return <Select mode="multiple" allowClear disabled={props.isDisabled} onChange={onChange} value={props.resp[props.question.id]} placeholder={props.question.placeholder} options={props.question.options}/>
            case QuestionType.RADIO:
                return <Radio.Group onChange={onChange} value={props.resp[props.question.id]} disabled={props.isDisabled}>
                    {
                        props.question.options?.map((opt, idx) => (
                            <Radio key={idx} value={opt.value}>{opt.label}</Radio>
                        ))
                    }
                </Radio.Group>
            case QuestionType.CHECKBOX:
                return <Checkbox.Group options={props.question.options} disabled={props.isDisabled} onChange={onChange} value={props.resp[props.question.id]}/>
            case QuestionType.SLIDER:
                return <Slider  onChange={onChange} value={props.resp[props.question.id]} disabled={props.isDisabled}/>
            case QuestionType.RANGE:
                return <Slider range onChange={onChange} value={props.resp[props.question.id]} disabled={props.isDisabled}/>
            case QuestionType.DATE:
                return <DatePicker onChange={onChange} value={props.resp[props.question.id]} disabled={props.isDisabled} />
            case QuestionType.DATE_RANGE:
                return <DatePicker.RangePicker onChange={onChange} value={props.resp[props.question.id]} disabled={props.isDisabled}/>

        }
    }

    return (
        <Form.Item label={props.question.label} name={props.question.label} rules={props.question.rules} initialValue={props.resp[props.question.id]}>
            {renderInput()}
        </Form.Item>
    )
};

export default QuestionAnswer;
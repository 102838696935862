import { jwtDecode } from "jwt-decode"
import { TokenModel } from "../_models/token_model/Token.model";
import { Role } from "../_types/Role.type";

const extractRole = (token: TokenModel|null): Role|null =>{
    if(!token){
        return null;
    }
    return token.roles
}
const isTokenExpired = (token?: TokenModel|null)=>{
    if(!token){
        return null;
    }
    const { exp } = token;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > exp;
}

export const TokenService = {
    setToken: (token: string) =>{
        localStorage.setItem('Token', token)
    },

    getToken: (): string|null =>{
        const tokenStr = localStorage.getItem('Token');
        if(!tokenStr){
            return null;
        }
        return tokenStr;
    },

    clearToken:()=>{
        localStorage.clear();
    },

    extractToken: (): TokenModel|null => {
        const tokenStr = TokenService.getToken();
        if(!tokenStr){
            return null;
        }
        let token: TokenModel = jwtDecode(tokenStr);
        return token;
    },

    getRole: (): Role | null => {
        const token: TokenModel | null = TokenService.extractToken();
        return extractRole(token);
    },

    isExpired: () => {
        const token: TokenModel | null = TokenService.extractToken();
        return isTokenExpired(token)
    },

    isValidRole:(role: Role)=>{
        const token: TokenModel | null = TokenService.extractToken();
        if(!token || isTokenExpired(token) || role != token.roles){
            return false;
        }
        return true;
    }

}
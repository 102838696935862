import { Button, Card, Col, Flex, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { LoginData } from '../../_models/User.model';
import { useNavigate } from 'react-router-dom';
import { AdminServices } from '../../_services/admin.services';
import { NotificationSvc } from '../../_services/notification.service';
import { TokenService } from '../../_services/token.service';
import Loader from '../../widgets/loader/loader';

const Login = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);

    const onFinish = (values: LoginData) => {
        setLoading(true)
        const adminCredentials: LoginData = {
            email: values.email,
            password: values.password
        }
        AdminServices.generateAdminToken(adminCredentials).then((response: any) => {
            setLoading(false)
            if (response?.success) {
                TokenService.setToken(response.payload)
                navigate('/admin')
            } else {
                NotificationSvc.openErrorMessage('Invalid password')
            }
        }).catch(error => {
            if (error)
                setLoading(false)
        })

    };

    return (
        <>
            <div className='page-content'>
                <Row>
                    <Col lg={{ span: 14, offset: 5 }} md={{ span: 20, offset: 2 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Card>
                            <Form layout="vertical" name="company-details" form={form} onFinish={onFinish} className="user-details-form-container">
                                <Typography.Title level={5} className="sub-heading marginTop-0">LOGIN</Typography.Title>
                                <Form.Item label="Email ID" name='email' rules={[{ required: true, type: 'email' }]}>
                                    <Input placeholder="Enter email ID" />
                                </Form.Item>
                                <Form.Item label="Password" name='password' rules={[{ required: true }]}>
                                    <Input.Password placeholder="Enter password" autoComplete="new-password" />
                                </Form.Item>
                                <Flex justify="flex-end">
                                    <Button type="primary" htmlType="submit" className="primary-btn">Submit</Button>
                                </Flex>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Loader loading={loading} />
        </>
    )
}

export default Login
import React from 'react';
import './ContactUs.css'
import { LinkedinOutlined, TwitterOutlined, createFromIconfontCN } from '@ant-design/icons';
import { Button, Col, Flex, Row, Space } from 'antd';
import CoverContent from '../../_components/CoverContent/CoverContent';
import CoverImage from '../../_components/CoverImage/CoverImage';

const ContactUs = () => {
  const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
  })
  return (
    <>
      <CoverImage coverImageUrl='./Kenya-India.png'>
        <CoverContent title='CONTACT US'></CoverContent>
      </CoverImage>
      <div className='page-content'>
        <Row>
          <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
            <h1 className='heading'>Contact Us</h1>
            <div className='sub-heading section-header'>Need support? Get in touch with us.</div>
            <div className='description'>Indo-German Chamber of Commerce (AHK India) and the Delegation of German Industry and Commerce for Eastern Africa (through its service entity AHK Services Eastern Africa Ltd.) provide implementation support to Indian and East African companies looking to further their responsible business practices. To learn more about how we support companies, get in touch with us.</div>
            {/* <div className='button-style marginTop-30'>
              <Button className='primary-btn'><a href='https://forms.office.com/r/bajiyUNcMY' target='_blank'>Click here</a></Button>
            </div> */}
            <Row gutter={24} className='marginTop-30'>
              <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} className='marginTop-30 contact-content-container'>
                <Row gutter={18}>
                  <Col lg={{ span: 9 }} md={{ span: 10 }} sm={{ span: 7 }} xs={{ span: 10 }}>
                    <div>
                      <img src='./India-Map.jpg' className='region-image-style' />
                    </div>
                  </Col>
                  <Col lg={{ span: 15 }} md={{ span: 14 }} sm={{ span: 17 }} xs={{ span: 14 }}>
                    <div className='font-weight-bold extra-small-font header-font-family' >Indo-German Chamber of Commerce</div>
                    <div className='extra-small-font marginTop-10'>Email: <a href="mailto:sustainmarkets@indo-german.com" target="_blank" className='link'>sustainmarkets@indo-german.com</a></div>
                    <div className='extra-small-font'>Website: <a href='http://www.indien.ahk.de/' target='_blank' className='link'>www.indien.ahk.de</a> | <a href='http://www.sustainmarkets.in/' target='_blank' className='link'>www.sustainmarkets.in</a></div>

                    <div className='marginTop-20 extra-small-font'>Indo-German Chamber of Commerce, Maker Tower ‘E’ Wing,<br />First Floor,Cuffe Parade, Mumbai – 400005,Maharashtra, India<br />Tel: +91-22-66652 121</div>
                    <Space className='marginTop-10' align='baseline'>
                      <a href='https://www.linkedin.com/company/sustainmarkets' target='_blank'>
                        <img src='./social_image/linkdin_sky_color.svg' />
                      </a>
                      <a href='https://in.linkedin.com/company/indo-german-chamber-of-commerce' target='_blank'>
                        <img src='./social_image/linkdin.svg' />
                      </a>
                      <a href='https://www.facebook.com/IndoGermanChamberofCommerce/' target='_blank'>
                        <img src='./social_image/facebook.svg' />
                      </a>
                      <a href='https://twitter.com/IndoGerman' target='_blank'>
                        <img src='./social_image/twitter.svg' />
                      </a>
                      <a href='https://www.youtube.com/c/IndoGermanChamberofCommerce' target='_blank'>
                        <img src='./social_image/youtube.svg' />
                      </a>
                    </Space>
                  </Col>
                </Row>
                <div className='button-style marginTop-30'>
                  <Button className='primary-btn'><a href='https://forms.office.com/r/bajiyUNcMY' target='_blank'>Contact Us</a></Button>
                </div>
              </Col>
              <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} className='marginTop-30 contact-content-container'>
                <Row gutter={14}>
                  <Col lg={{ span: 8 }} md={{ span: 10 }} sm={{ span: 7 }} xs={{ span: 10 }}>
                    <div >
                      <img src='./East-Africa-Map.jpg' className='region-image-style' />
                    </div>
                  </Col>
                  <Col lg={{ span: 16 }} md={{ span: 14 }} sm={{ span: 17 }} xs={{ span: 14 }}>
                    <div className='font-weight-bold extra-small-font header-font-family'>The Delegation of German Industry and Commerce for Eastern Africa</div>

                    <div className='extra-small-font marginTop-10'>Email: <a href="mailto:office@kenya-ahk.co.ke" target="_blank" className='link'>office@kenya-ahk.co.ke</a></div>
                    <div className='extra-small-font'>
                      Website: <a href='http://www.kenia.ahk.de/' target='_blank' className='link'>www.kenia.ahk.de</a></div>

                    <div className='marginTop-20 extra-small-font'>West Park Suites, 6th floor Ojijo Road (opp. Parklands Police Station),<br />P.O BOX 19016, 00100 Parklands, Nairobi, Kenya<br />
                      Tel: +254 20 663 - 3000</div>
                    <Space className='marginTop-10' align='baseline'>
                      <a href='https://www.linkedin.com/company/delegation-of-german-industry-and-commerce-in-kenya/about/' target='_blank'>
                        <img src='./social_image/linkdin.svg' />
                      </a>
                      <a href='https://www.youtube.com/channel/UCz72d9BkHGcXPEpMNVN19sQ' target='_blank'>
                        <img src='./social_image/youtube.svg' alt="" className='svg-color' />
                      </a>

                    </Space>
                  </Col>
                </Row>
                <div className='button-style marginTop-30'>
                  <Button className='primary-btn'><a href='https://www.kenia.ahk.de/contact-us' target='_blank'>Contact Us</a></Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </div >
    </>
  )
};

export default ContactUs;
import { Button, Card, Col, Empty, Flex, Form, Row, StepProps, Steps } from 'antd';
import React, { createRef, useEffect, useRef, useState } from 'react';
import QuestionAnswer from '../QuestionAnswer/QuestionAnswer';
import { useNavigate } from 'react-router-dom';
import { Questionnaire } from '../../_models/Questionnaire.model';
import { QuestionnaireService } from '../../_services/questionnaire.service';
import { useUser } from '../../_contexts/UserContext/UserContext';
import { UserData } from '../../_models/User.model';
import { Question } from '../../_models/Question.model';
import './SurveyForm.css';
import { NotificationSvc } from '../../_services/notification.service';
import Loader from '../../widgets/loader/loader';
interface SurveyFormProps {
    questionnaire: Questionnaire,
    response: any,
    isFormDisabled?: boolean,
    setResponse: (data: any) => void
}
const SurveyForm = ({ questionnaire, response, isFormDisabled, setResponse }: SurveyFormProps) => {
    const { userData, setUserData } = useUser();
    const navigate = useNavigate();
    const [stepItems, setStepItems] = useState<StepProps[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [currents, setCurrent] = useState(0);
    const [loading, setLoading] = useState<boolean>(false)
    const inputRefs = useRef<any[]>([]);

    useEffect(() => {
        if (inputRefs.current.length > 0)
            inputRefs.current[0].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });

    }, [currents, inputRefs.current.length])

    useEffect(() => {
        if (questionnaire?.sections) {
            setStepItems(questionnaire.sections.map(s => ({ key: s.id, title: s.label })));
        }
    }, [questionnaire])

    const prev = () => {
        setCurrent(currents - 1);
    };

    const onFinish = (value: any) => {
        setStepItems(prevStepItems => {
            // Create a copy of the original array
            const updatedStepItems = [...prevStepItems];
            // Update the desired property of the first item in the array
            updatedStepItems[currents].status = "finish";
            return updatedStepItems;
        });
        setProgress((stepItems.filter(s => s.status == 'finish').length / stepItems.length) * 100)
        if (questionnaire.sections.length - 1 === currents) {
            setLoading(true)
            if (userData && userData?.email) {
                QuestionnaireService.submitQuestionnaireResponse(questionnaire, response, userData.email).then((result: any) => {
                    setLoading(false)
                    if (result.data.success) {
                        navigate("/thank-you")
                    } else {
                        NotificationSvc.openErrorMessage(result.data.message)
                    }
                }).catch(error => {
                    if (error)
                        setLoading(false)
                })
            }
        } else {
            setCurrent(currents + 1);
        }
    }
    const onRespChange = (resp: any) => {
        setResponse({ ...response, ...resp });
    }
    const onFinishFaild = (value: any) => {
        console.log(value)
    }

    const onNext = () => {
        if (userData && userData?.email) {
            QuestionnaireService.saveQuestionnaireResponse(questionnaire, response, userData.email).then((result: any) => {
                if (!result.data.success)
                    NotificationSvc.openErrorMessage(result.data.message)
            })
        }

    }


    let progressProp = {};
    if (!isFormDisabled) {
        progressProp = { percent: progress };
    }
    return (
        <>
            <Row>
                <Col lg={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                    <Card>
                        {stepItems.length ? <Steps current={currents} {...progressProp} labelPlacement="vertical" items={stepItems} /> : <></>}
                    </Card>
                    <Card>
                        {questionnaire?.sections?.length ? <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFaild} >

                            <div className="questionnaire-container">

                                <ol type="1" className='font-weght-bold' >
                                    {questionnaire.sections[currents].questions.map((q, idx) =>
                                        <li key={idx} ref={(ref) => inputRefs.current[idx] = ref}>
                                            <QuestionAnswer key={q.id} question={q} resp={response} isDisabled={!!isFormDisabled} questionIndex={idx + 1} onRespChange={onRespChange} />
                                        </li>
                                    )}
                                </ol>
                                <Flex justify="space-between" className="next-prev-container">
                                    <Button onClick={() => prev()} disabled={currents === 0}>
                                        Previous
                                    </Button>
                                    {currents < questionnaire.sections.length - 1 && (
                                        <Button type="primary" htmlType="submit" onClick={onNext}>
                                            Next
                                        </Button>
                                    )}
                                    {currents === questionnaire.sections.length - 1 && (
                                        <Button type="primary" className="primary-btn" disabled={!!isFormDisabled} htmlType="submit" onClick={onFinishFaild}>
                                            Submit
                                        </Button>
                                    )}
                                </Flex>

                            </div>
                        </Form> : <Empty />}
                    </Card>
                </Col>
            </Row>
            <Loader loading={loading} />
        </>
    )
}

export default SurveyForm;
import React from 'react';
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout as AntLayout } from 'antd';
import NavBar from "../../_components/Navbar/Navbar";
import SurveyFooter from "../../_components/Footer/Footer";
import Copyright from "../../_components/Copyright/Copyright";
import './Layout.css';
import { useLocation } from 'react-router-dom'
import { globalRoute } from '../../_services/globalRoute.service';
import { TokenService } from '../../_services/token.service';
import { Role } from '../../_types/Role.type';

const Layout = () => {
    const { Header, Content, Footer } = AntLayout;
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const surveyRoutes = ["/survey", "/thank-you"];
    useEffect(() => {
        if (surveyRoutes.includes(pathname)) {
            navigate("/"); // Redirect to home page if user tries to access survey page directly
        }
    }, []);

    const checkValidUser = () => {
        const role: any = TokenService.getRole()
        const roleValidation = TokenService.isValidRole(role)

        if (role === Role.ADMIN) {
            if (roleValidation) {
                navigate('/admin')
            }
        }

    }

    useEffect(() => {

        checkValidUser()
        console.log('Home pa')
    }, [])

    globalRoute.navigate = navigate;


    return (
        <>
            <AntLayout className="layout-container">
                <Header className="nav-container">
                    <NavBar />
                </Header>
                <Content>
                    <Outlet />
                </Content>
                <Footer className="top-footer-container">
                    <SurveyFooter />
                </Footer>
                <Footer className="bottom-footer-container">
                    <Copyright />
                </Footer>
            </AntLayout>
        </>
    )
};

export default Layout;
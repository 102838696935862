export const APP_CONSTANT = {
    TITLE:{
        USER_DETAILS:'',
        SURVEY:''
    },
    STYLE: {
        THEME: {
            PRIMARY_COLOR: '#F98232',
            PRIMARY_COLOR_DARK_TONE: '#fa6807',
            SECONDARY_COLOR: '#007CBB',
            TERITORY_COLOR: '#0C2831',
            MUTED_COLOR: '#7D7D7D',
            WHITE_COLOR_LIGHT_TONE: '#FFF',
            WHITE_COLOR_DARK_TONE: '#F9F9F9',
            BLACK_COLOR_LIGHT_TONE: '#232323',
            BLACK_COLOR_DARK_TONE: '#000'
        },
        FONT: {
        }
    }
}
import { UserData } from "../_models/User.model";
import { ApiSvc } from "./api.service";

export const UserService = {
    USER_BASE_URL: '/User',
    createUser: async (data: UserData) => {
        return ApiSvc.post(`${UserService.USER_BASE_URL}/create`, data);
    },
    generateUserToken: async (data: UserData) => {
        const response = await ApiSvc.post(`${UserService.USER_BASE_URL}/generateToken`, data);
        return response?.data
    },

    resendOtp: async (data: UserData) => {
        return ApiSvc.post(`${UserService.USER_BASE_URL}/resendOtp`, data)
    }

};